/* Slider */
.slick-loading .slick-list {
    background: #fff center center no-repeat;
}

/* Icons */

/* Arrows */
.slick-prev,
.slick-next {
    font-size: 0;
    line-height: 0;

    position: absolute;
    top: 50%;

    display: block;

    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);

    cursor: pointer;

    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
    color: transparent;
    outline: none;
    background: transparent;
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
    opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
    opacity: .25;
}

.slick-prev:before,
.slick-next:before {
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;

    opacity: .75;
    color: white;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
    left: -25px;
}

[dir='rtl'] .slick-prev {
    right: -25px;
    left: auto;
}

.slick-prev:before {
    content: '←';
}

[dir='rtl'] .slick-prev:before {
    content: '→';
}

.slick-next {
    right: -25px;
}

[dir='rtl'] .slick-next {
    right: auto;
    left: -25px;
}

.slick-next:before {
    content: '→';
}

[dir='rtl'] .slick-next:before {
    content: '←';
}

/* Dots */
.slick-dotted.slick-slider {
    margin-bottom: 30px;
}

.slick-dots {
    position: absolute;
    /* bottom: -25px; */

    display: block;

    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;
}

.slick-dots li {
    position: relative;

    display: inline-block;

    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;

    cursor: pointer;
}

.slick-dots li button {
    font-size: 0;
    line-height: 0;

    display: block;

    width: 20px;
    height: 20px;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
    outline: none;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
    opacity: 1;
}

.slick-dots li button:before {
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;

    width: 20px;
    height: 20px;

    content: '•';
    text-align: center;

    opacity: .25;
    color: black;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
    opacity: .75;
    color: black;
}


/* custom css */
.slick-prev {
    left:-25px;
    z-index: 1;
    color: #f05c27;
    height: 50px;

}

.slick-prev:hover {
    color: #d04c1c;
}

/* .banner-prev{
    left: 20px;
    z-index: 1;
    color: red; 
} */

.slick-next {
    right: -20px;
    z-index: 1;
    color: #f05c27;
    height: 50px;
}

.slick-next:hover {
    color: #d04c1c;
}

.slick-dots__hottype {


    position: absolute;
    bottom: -68px !important;

    display: block;

    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;

}

.slick-dots li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 8px !important;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
}

.slick-dots li button {
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 50%;
    opacity: 0.6;
    overflow: hidden;
}


/* Custom Slick Dots */

@keyframes loading {
    from {
        width: 0%;
    }

    to {
        width: 100%;
    }
}

.ft-slick__dots--custom {
    height: 8px;
    width: 14px;
    background-color: #e5e7e9;
    border-radius: 4px;
    position: relative;
}

.ft-slick__dots__hottype {
    height: 8px;
    width: 14px;
    background-color: #d04c1c;
    border-radius: 4px;
    position: relative;
}

.ft-slick__dots__category_slider {
    height: 8px;
    width: 14px;
    background-color: #d04c1c;
    border-radius: 4px;
    position: relative;
}

.slick-dots .slick-active .ft-slick__dots__hottype {
    width: 56px;
    top: 0px;
    /* margin-top: -40px; */
    overflow: hidden;
}

.slick-dots .slick-active .ft-slick__dots__category_slider {
    width: 56px;
    top: 0px;
    /* margin-top: -40px; */
    overflow: hidden;
}


.slick-dots li {
    width: 14px;
    margin: 0 2px;
    transition: width 0.3s ease-in-out;
}

.slick-dots .slick-active {
    width: 56px;
    transition: width 0.3s ease-in-out;
}

.slick-dots .slick-active .ft-slick__dots--custom {
    width: 56px;
    top: 0px;
    margin-top: -40px;
    overflow: hidden;
}

.slick-dots .slick-active .ft-slick__dots--custom.loading {
    height: 8px;
    animation: loading 5s ease-in;
    background-image: linear-gradient(270deg, #4fd15a, #03ac0e);
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 4px;
}

;



/*-------------------------------------
 dot edit for mobile device 
 -------------------------------------*/



/*--------------------------------------------
 Extra small devices (phones, 600px and down) 
 ---------------------------------------------*/


@media only screen and (max-width: 600px) {
    .slick-dots {

        bottom: -60px;


    }
}



/*--------------------------------------------------------------
 Small devices (portrait tablets and large phones, 600px and up) 
 ---------------------------------------------------------------*/
@media only screen and (min-width: 600px) {
    .slick-dots {

        bottom: -60px;


    }
}




/*------------------------------------------------
 Medium devices (landscape tablets, 768px and up) 
 -------------------------------------------------*/
@media only screen and (min-width: 768px) {
    .slick-dots {

        bottom: -25px;


    }
}



/*----------------------------------------------
 Large devices (laptops/desktops, 992px and up) 
 ----------------------------------------------*/
@media only screen and (min-width: 992px) {}




/*--------------------------------------------------------------
 Extra large devices (large laptops and desktops, 1200px and up) 
 ---------------------------------------------------------------*/
@media only screen and (min-width: 1200px) {}