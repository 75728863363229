@tailwind base;
:root {
    --primary: #ffffff;
    --secondary: #cbd5e1;
}

/* Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: var(--secondary) var(--primary);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 10px;
}

*::-webkit-scrollbar-track {
    background: var(--primary);
    border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
    background-color: var(--secondary);
    border-radius: 15px;
    border: 2px solid var(--primary);
}
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');