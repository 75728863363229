*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
/* img{
  transition: transform .2s; 
}

img:hover{
  transform: scale(1.1);
} */
body{
  background-color: #F4F7FD;
  font-family: 'Poppins', sans-serif;
  /* -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; */
}

.active{
  color: blueviolet;
}

.font-rubik {
  font-family: 'Rubik', sans-serif;
}




.rpv-core__display--block-medium:has(div[aria-describedby="rpv-core__tooltip-body-open"]),
.rpv-core__display--block-medium:has(div[aria-describedby="rpv-core__tooltip-body-get-file"]),
.rpv-core__display--block-medium:has(div[aria-describedby="rpv-core__tooltip-body-print"]),
.rpv-default-layout__sidebar-header:has(div[aria-describedby="rpv-core__tooltip-body-default-layout-sidebar-tab-2"]),
.rpv-default-layout__sidebar-header:has(div[aria-describedby="rpv-core__tooltip-body-default-layout-sidebar-tab-1"]),
.rpv-core__display--block:has(button[data-testid="print__menu"]),
.rpv-core__display--block:has(button[data-testid="get-file__download-menu"]),
.rpv-core__display--block:has(button[data-testid="open__menu"]) {
  display: none !important;
}

@media print {
  body {
    display: none;
  }
}