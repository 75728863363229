.parent{
    display: block;
    position: relative;
    /* float: left; */
    line-height: 30px;
    

}

.parent>ul{
   max-height: 300px;
}


.subparent>ul{
    left: 100%;
    /* margin-top: 0px;
     */
     top: -13%;
     height: min-content;
    
     /* top: -80%; */
}



.child{
    display: none;
    background-color: white;
    width: max-content;
    padding: 5px 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    
    
}

.child>.subparent{
    height: min-content;
}


.parent:hover>.child {
	display: block;
	position: absolute;
}




.parent:hover>.arrowbutton{
    transform: rotate(3.142rad);
    background-color: red;
    display: none;
    
}







