.pagination {
    margin: 0;
    padding: 0;
    text-align: center;
    color: red;
  }
  .pagination li {
    display: inline
  }
  .pagination li a {
    display: inline-block;
    text-decoration: none;
    padding: 5px 10px;
    color: #000
  }
  
  /* Active and Hoverable Pagination */
  .pagination li a {
    border-radius: 5px;
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s
      
  }
  .pagination li a.active {
    background-color: #4caf50;
    color: #fff
  }
  .pagination li a:hover:not(.active) {
    background-color: #ddd;
  } 
  
  /* border-pagination */
  .b-pagination-outer {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    overflow: hidden;
    display: flex
  }
  .border-pagination {
    margin: 0 auto;
    padding: 0;
    text-align: center
  }
  .border-pagination li {
    display: inline;
  
  }
  .border-pagination li a {
    display: block;
    text-decoration: none;
    color: #000;
    padding: 5px 10px;
    border: 1px solid #ddd;
    float: left;
  
  }
  .border-pagination li a {
    -webkit-transition: background-color 0.4s;
    transition: background-color 0.4s
  }
  .border-pagination li a.active {
    background-color: #4caf50;
    color: #fff;
  }
  .border-pagination li a:hover:not(.active) {
    background: #ddd;
  }

  .active{
    background-color: blue;
    border: 1px solid gray;
    border-radius: 50px;
    color: burlywood;
  }

  [aria-label="Go to first page"] {
    color: red;
    background-color: green;
    content: 'h';
    }