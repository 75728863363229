.mainbox {
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    
}

/*label {*/
/*    !* border: 1px solid white; *!*/
/*    position: relative;*/
/*    background: #41779a;*/
/*    height: 50px;*/
/*    width: 150px;*/
/*    border-radius: 35px;*/
/*    line-height: 50px;*/
/*    text-align: center;*/
/*    font-size: 15px;*/
/*    font-weight: 500;*/
/*    !* text-transform: uppercase; *!*/
/*    display: block;*/
/*    color: white;*/
/*    cursor: pointer;*/
/*    box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);*/
/*    transition: all 0.3s ease;*/
/*}*/

/*label:hover {*/
/*  letter-spacing: 1px;*/
/*}*/

#check {
    display: none;
}

#check:checked {
    opacity: 1;
}

.media-icons{
    position: absolute;
    left: 50%;
    top: 46px;
    transform: translateX(-50%);
    background: #fff;
    width: 140%;
    height: 110%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 35px;
    padding: 4px;
    z-index: 1;
    opacity: 0;
    pointer-events: none;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
    transition: all 0.3s ease;
}

#check:checked ~ .media-icons {
    opacity: 1;
    pointer-events: auto;
    top: 60px;
}

.media-icons::before {
    content: '';
    width: 18px;
    height: 18px;
    position: absolute;
    left: 50%;
    background: #fff;
    bottom: 45px;
    transform: translateX(-50%) rotate(45deg);
    z-index: -1;
}

.media-icons a {
    font-size: 22px;
    color: #0984e3;
    transition: all 0.3s ease;
}

.media-icons a:hover {
    transform: translateY(-2px);
}

.media-icons a:nth-child(1):hover {
    color: #426782;
}

.media-icons a:nth-child(2):hover {
    color: #1da1f2;
}

.media-icons a:nth-child(3):hover {
    color: #e1306c;
}

.media-icons a:nth-child(4):hover {
    color: #ff0000;
}
   