

.size{
    
        --tw-size-body: #374151;
        --tw-size-headings: #111827;
        --tw-size-lead: #4b5563;
        --tw-size-links: #111827;
        --tw-size-bold: #111827;
        --tw-size-counters: #6b7280;
        --tw-size-bullets: #d1d5db;
        --tw-size-hr: #e5e7eb;
        --tw-size-quotes: #111827;
        --tw-size-quote-borders: #e5e7eb;
        --tw-size-captions: #6b7280;
        --tw-size-code: #111827;
        --tw-size-pre-code: #e5e7eb;
        --tw-size-pre-bg: #1f2937;
        --tw-size-th-borders: #d1d5db;
        --tw-size-td-borders: #e5e7eb;
        --tw-size-invert-body: #d1d5db;
        --tw-size-invert-headings: #fff;
        --tw-size-invert-lead: #9ca3af;
        --tw-size-invert-links: #fff;
        --tw-size-invert-bold: #fff;
        --tw-size-invert-counters: #9ca3af;
        --tw-size-invert-bullets: #4b5563;
        --tw-size-invert-hr: #374151;
        --tw-size-invert-quotes: #f3f4f6;
        --tw-size-invert-quote-borders: #374151;
        --tw-size-invert-captions: #9ca3af;
        --tw-size-invert-code: #fff;
        --tw-size-invert-pre-code: #d1d5db;
        --tw-size-invert-pre-bg: rgb(0 0 0 / 50%);
        --tw-size-invert-th-borders: #4b5563;
        --tw-size-invert-td-borders: #374151;
        font-size: 1rem;
        line-height: 1.75;
    
}

.size{
    color: var(--tw-size-body);
    max-width: 100%;
}




.size {
    color: var(--tw-size-body);
    /* max-width: 65ch; */
}
.size :where(p):not(:where([class~="not-size"] *)) {
    margin-top: 1.25em;
    margin-bottom: 1.25em;
}
.size :where([class~="lead"]):not(:where([class~="not-size"] *)) {
    color: var(--tw-size-lead);
    font-size: 1.25em;
    line-height: 1.6;
    margin-top: 1.2em;
    margin-bottom: 1.2em;
}
.size :where(a):not(:where([class~="not-size"] *)) {
    color: var(--tw-size-links);
    text-decoration: underline;
    font-weight: 500;
}
.size :where(strong):not(:where([class~="not-size"] *)) {
    color: var(--tw-size-bold);
    font-weight: 600;
}
.size :where(a strong):not(:where([class~="not-size"] *)) {
    color: inherit;
}
.size :where(blockquote strong):not(:where([class~="not-size"] *)) {
    color: inherit;
}
.size :where(thead th strong):not(:where([class~="not-size"] *)) {
    color: inherit;
}
.size :where(ol):not(:where([class~="not-size"] *)) {
    list-style-type: decimal;
    margin-top: 1.25em;
    margin-bottom: 1.25em;
    padding-left: 1.625em;
}
.size :where(ol[type="A"]):not(:where([class~="not-size"] *)) {
    list-style-type: upper-alpha;
}
.size :where(ol[type="a"]):not(:where([class~="not-size"] *)) {
    list-style-type: lower-alpha;
}
.size :where(ol[type="A" s]):not(:where([class~="not-size"] *)) {
    list-style-type: upper-alpha;
}
.size :where(ol[type="a" s]):not(:where([class~="not-size"] *)) {
    list-style-type: lower-alpha;
}
.size :where(ol[type="I"]):not(:where([class~="not-size"] *)) {
    list-style-type: upper-roman;
}
.size :where(ol[type="i"]):not(:where([class~="not-size"] *)) {
    list-style-type: lower-roman;
}
.size :where(ol[type="I" s]):not(:where([class~="not-size"] *)) {
    list-style-type: upper-roman;
}
.size :where(ol[type="i" s]):not(:where([class~="not-size"] *)) {
    list-style-type: lower-roman;
}
.size :where(ol[type="1"]):not(:where([class~="not-size"] *)) {
    list-style-type: decimal;
}
.size :where(ul):not(:where([class~="not-size"] *)) {
    list-style-type: disc;
    margin-top: 1.25em;
    margin-bottom: 1.25em;
    padding-left: 1.625em;
}
.size :where(ol > li):not(:where([class~="not-size"] *))::marker {
    font-weight: 400;
    color: var(--tw-size-counters);
}
.size :where(ul > li):not(:where([class~="not-size"] *))::marker {
    color: var(--tw-size-bullets);
}
.size :where(hr):not(:where([class~="not-size"] *)) {
    border-color: var(--tw-size-hr);
    border-top-width: 1px;
    margin-top: 3em;
    margin-bottom: 3em;
}
.size :where(blockquote):not(:where([class~="not-size"] *)) {
    font-weight: 500;
    font-style: italic;
    color: var(--tw-size-quotes);
    border-left-width: 0.25rem/* 4px */;
    border-left-color: var(--tw-size-quote-borders);
    quotes: "\201C""\201D""\2018""\2019";
    margin-top: 1.6em;
    margin-bottom: 1.6em;
    padding-left: 1em;
}
.size :where(blockquote p:first-of-type):not(:where([class~="not-size"] *))::before {
    content: open-quote;
}
.size :where(blockquote p:last-of-type):not(:where([class~="not-size"] *))::after {
    content: close-quote;
}
.size :where(h1):not(:where([class~="not-size"] *)) {
    color: var(--tw-size-headings);
    font-weight: 800;
    font-size: 2.25em;
    margin-top: 0;
    margin-bottom: 0.8888889em;
    line-height: 1.1111111;
}
.size :where(h1 strong):not(:where([class~="not-size"] *)) {
    font-weight: 900;
    color: inherit;
}
.size :where(h2):not(:where([class~="not-size"] *)) {
    color: var(--tw-size-headings);
    font-weight: 700;
    font-size: 1.5em;
    margin-top: 2em;
    margin-bottom: 1em;
    line-height: 1.3333333;
}
.size :where(h2 strong):not(:where([class~="not-size"] *)) {
    font-weight: 800;
    color: inherit;
}
.size :where(h3):not(:where([class~="not-size"] *)) {
    color: var(--tw-size-headings);
    font-weight: 600;
    font-size: 1.25em;
    margin-top: 1.6em;
    margin-bottom: 0.6em;
    line-height: 1.6;
}
.size :where(h3 strong):not(:where([class~="not-size"] *)) {
    font-weight: 700;
    color: inherit;
}
.size :where(h4):not(:where([class~="not-size"] *)) {
    color: var(--tw-size-headings);
    font-weight: 600;
    margin-top: 1.5em;
    margin-bottom: 0.5em;
    line-height: 1.5;
}
.size :where(h4 strong):not(:where([class~="not-size"] *)) {
    font-weight: 700;
    color: inherit;
}
.size :where(img):not(:where([class~="not-size"] *)) {
    margin-top: 2em;
    margin-bottom: 2em;
}
.size :where(figure > *):not(:where([class~="not-size"] *)) {
    margin-top: 0;
    margin-bottom: 0;
}
.size :where(figcaption):not(:where([class~="not-size"] *)) {
    color: var(--tw-size-captions);
    font-size: 0.875em;
    line-height: 1.4285714;
    margin-top: 0.8571429em;
}
.size :where(code):not(:where([class~="not-size"] *)) {
    color: var(--tw-size-code);
    font-weight: 600;
    font-size: 0.875em;
}
.size :where(code):not(:where([class~="not-size"] *))::before {
    content: "`";
}
.size :where(code):not(:where([class~="not-size"] *))::after {
    content: "`";
}
.size :where(a code):not(:where([class~="not-size"] *)) {
    color: inherit;
}
.size :where(h1 code):not(:where([class~="not-size"] *)) {
    color: inherit;
}
.size :where(h2 code):not(:where([class~="not-size"] *)) {
    color: inherit;
    font-size: 0.875em;
}
.size :where(h3 code):not(:where([class~="not-size"] *)) {
    color: inherit;
    font-size: 0.9em;
}
.size :where(h4 code):not(:where([class~="not-size"] *)) {
    color: inherit;
}
.size :where(blockquote code):not(:where([class~="not-size"] *)) {
    color: inherit;
}
.size :where(thead th code):not(:where([class~="not-size"] *)) {
    color: inherit;
}
.size :where(pre):not(:where([class~="not-size"] *)) {
    color: var(--tw-size-pre-code);
    background-color: var(--tw-size-pre-bg);
    overflow-x: auto;
    font-weight: 400;
    font-size: 0.875em;
    line-height: 1.7142857;
    margin-top: 1.7142857em;
    margin-bottom: 1.7142857em;
    border-radius: 0.375rem/* 6px */;
    padding-top: 0.8571429em;
    padding-right: 1.1428571em;
    padding-bottom: 0.8571429em;
    padding-left: 1.1428571em;
}
.size :where(pre code):not(:where([class~="not-size"] *)) {
    background-color: transparent;
    border-width: 0;
    border-radius: 0;
    padding: 0;
    font-weight: inherit;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
}
.size :where(pre code):not(:where([class~="not-size"] *))::before {
    content: none;
}
.size :where(pre code):not(:where([class~="not-size"] *))::after {
    content: none;
}
.size :where(table):not(:where([class~="not-size"] *)) {
    width: 100%;
    table-layout: auto;
    text-align: left;
    margin-top: 2em;
    margin-bottom: 2em;
    font-size: 0.875em;
    line-height: 1.7142857;
}
.size :where(thead):not(:where([class~="not-size"] *)) {
    border-bottom-width: 1px;
    border-bottom-color: var(--tw-size-th-borders);
}
.size :where(thead th):not(:where([class~="not-size"] *)) {
    color: var(--tw-size-headings);
    font-weight: 600;
    vertical-align: bottom;
    padding-right: 0.5714286em;
    padding-bottom: 0.5714286em;
    padding-left: 0.5714286em;
}
.size :where(tbody tr):not(:where([class~="not-size"] *)) {
    border-bottom-width: 1px;
    border-bottom-color: var(--tw-size-td-borders);
}
.size :where(tbody tr:last-child):not(:where([class~="not-size"] *)) {
    border-bottom-width: 0;
}
.size :where(tbody td):not(:where([class~="not-size"] *)) {
    vertical-align: baseline;
}
.size :where(tfoot):not(:where([class~="not-size"] *)) {
    border-top-width: 1px;
    border-top-color: var(--tw-size-th-borders);
}
.size :where(tfoot td):not(:where([class~="not-size"] *)) {
    vertical-align: top;
}
.size {
    --tw-size-body: #374151;
    --tw-size-headings: #111827;
    --tw-size-lead: #4b5563;
    --tw-size-links: #111827;
    --tw-size-bold: #111827;
    --tw-size-counters: #6b7280;
    --tw-size-bullets: #d1d5db;
    --tw-size-hr: #e5e7eb;
    --tw-size-quotes: #111827;
    --tw-size-quote-borders: #e5e7eb;
    --tw-size-captions: #6b7280;
    --tw-size-code: #111827;
    --tw-size-pre-code: #e5e7eb;
    --tw-size-pre-bg: #1f2937;
    --tw-size-th-borders: #d1d5db;
    --tw-size-td-borders: #e5e7eb;
    --tw-size-invert-body: #d1d5db;
    --tw-size-invert-headings: #fff;
    --tw-size-invert-lead: #9ca3af;
    --tw-size-invert-links: #fff;
    --tw-size-invert-bold: #fff;
    --tw-size-invert-counters: #9ca3af;
    --tw-size-invert-bullets: #4b5563;
    --tw-size-invert-hr: #374151;
    --tw-size-invert-quotes: #f3f4f6;
    --tw-size-invert-quote-borders: #374151;
    --tw-size-invert-captions: #9ca3af;
    --tw-size-invert-code: #fff;
    --tw-size-invert-pre-code: #d1d5db;
    --tw-size-invert-pre-bg: rgb(0 0 0 / 50%);
    --tw-size-invert-th-borders: #4b5563;
    --tw-size-invert-td-borders: #374151;
    font-size: 1rem/* 16px */;
    line-height: 1.75;
}
.size :where(video):not(:where([class~="not-size"] *)) {
    margin-top: 2em;
    margin-bottom: 2em;
}
.size :where(figure):not(:where([class~="not-size"] *)) {
    margin-top: 2em;
    margin-bottom: 2em;
}
.size :where(li):not(:where([class~="not-size"] *)) {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}
.size :where(ol > li):not(:where([class~="not-size"] *)) {
    padding-left: 0.375em;
}
.size :where(ul > li):not(:where([class~="not-size"] *)) {
    padding-left: 0.375em;
}
.size :where(.size > ul > li p):not(:where([class~="not-size"] *)) {
    margin-top: 0.75em;
    margin-bottom: 0.75em;
}
.size :where(.size > ul > li p):not(:where([class~="not-size"] *)) {
    margin-top: 0.75em;
    margin-bottom: 0.75em;
}
.size :where(.size > ul > li > *:first-child):not(:where([class~="not-size"] *)) {
    margin-top: 1.25em;
}
.size :where(.size > ul > li > *:first-child):not(:where([class~="not-size"] *)) {
    margin-top: 1.25em;
}
.size :where(.size > ul > li > *:last-child):not(:where([class~="not-size"] *)) {
    margin-bottom: 1.25em;
}
.size :where(.size > ul > li > *:last-child):not(:where([class~="not-size"] *)) {
    margin-bottom: 1.25em;
}
.size :where(.size > ol > li > *:first-child):not(:where([class~="not-size"] *)) {
    margin-top: 1.25em;
}
.size :where(.size > ol > li > *:first-child):not(:where([class~="not-size"] *)) {
    margin-top: 1.25em;
}
.size :where(.size > ol > li > *:last-child):not(:where([class~="not-size"] *)) {
    margin-bottom: 1.25em;
}
.size :where(.size > ol > li > *:last-child):not(:where([class~="not-size"] *)) {
    margin-bottom: 1.25em;
}
.size :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-size"] *)) {
    margin-top: 0.75em;
    margin-bottom: 0.75em;
}
.size :where(hr + *):not(:where([class~="not-size"] *)) {
    margin-top: 0;
}
.size :where(h2 + *):not(:where([class~="not-size"] *)) {
    margin-top: 0;
}
.size :where(h3 + *):not(:where([class~="not-size"] *)) {
    margin-top: 0;
}
.size :where(h4 + *):not(:where([class~="not-size"] *)) {
    margin-top: 0;
}
.size :where(thead th:first-child):not(:where([class~="not-size"] *)) {
    padding-left: 0;
}
.size :where(thead th:last-child):not(:where([class~="not-size"] *)) {
    padding-right: 0;
}
.size :where(tbody td, tfoot td):not(:where([class~="not-size"] *)) {
    padding-top: 0.5714286em;
    padding-right: 0.5714286em;
    padding-bottom: 0.5714286em;
    padding-left: 0.5714286em;
}
.size :where(tbody td:first-child, tfoot td:first-child):not(:where([class~="not-size"] *)) {
    padding-left: 0;
}
.size :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="not-size"] *)) {
    padding-right: 0;
}
.size :where(.size > :first-child):not(:where([class~="not-size"] *)) {
    margin-top: 0;
}
.size :where(.size > :first-child):not(:where([class~="not-size"] *)) {
    margin-top: 0;
}
.size :where(.size > :last-child):not(:where([class~="not-size"] *)) {
    margin-bottom: 0;
}
.size :where(.size > :last-child):not(:where([class~="not-size"] *)) {
    margin-bottom: 0;
}