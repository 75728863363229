.navbar.active {
    background-color: #F9FAFB !important;
}


.list-dropdown {

}

.list-dropdown-submenu {
    display: none;
    /* transform: scaleX(0); */
    /* z-index: 2; */
}

.list-dropdown:hover > .list-dropdown-submenu {
    /* transform: scaleX(1); */
    display: block;
    /* visibility: visible; */
    /* z-index: ; */
}


.primary-search {
    float: left;
    position: relative;
}

.primary-search:before {
    position: absolute;
    top: -10px;
    left: 8%;
    margin-left: -10px;
    content: "";
    display: block;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #E5E7EB;
}
  